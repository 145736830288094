import { Link } from "react-router-dom";

export const ORTableRowActions = ({
    item,
    itemStatus,
    userRole,
    onAction,
    isOverlapping = true
  }) => {

    const isShipManager = userRole === "SHIPMANAGER";
    const isFleetManager = userRole === "FLEETMANAGER";
    const buttonClassName = "btn btn-sm p-1 py-0 btn-outline-";
    // debugger;
    let element = (
      // <Link
      //   to={"/offhire/report/view"}
      //   state={item}
      //   className={buttonClassName + "secondary "}
      //   data-toggle="tooltip"
      //   data-placement="top"
      //   title="View Technical Offhire Report"
      // >
      //   <i className="fa fa-eye"></i>
      // </Link>
      <button 
      className={buttonClassName + "secondary "}
      onClick={()=> onAction({
        action: "view",
        state: item
      })}>
        <i className="fa fa-eye"></i>
      </button>
    );

    if (isShipManager) {
      // Create Report
      if (!isOverlapping) {
        if (itemStatus === "INCOMPLETE") {
          element = (
            <>
              {element}
              <Link
                className={buttonClassName + "info m-1"}
                to={"/offhire/report/create"}
                state={item}
                data-toggle="tooltip"
                data-placement="top"
                title="Create Technical Offhire Report"
              >
                <i className="fa fa-file-o"></i>
              </Link>
            </>
          );
        } else {
          element = (
            <>
              {element}
              <Link
                to={"/offhire/report/addon"}
                state={item}
                className={buttonClassName + "secondary"}
                data-toggle="tooltip"
                data-placement="top"
                title="Add Technical Offhire Report"
              >
                <i className="fa fa-plus"></i>
              </Link>
            </>
          );
        }
      }
    }
      // itemStatus === "APPROVED" ||
      if ((isFleetManager && (itemStatus === "APPROVED" || itemStatus === "PENDING"))
        || (isShipManager && (itemStatus === "REJECTED" || itemStatus === "PENDING")))
        element = (
          <>
            {element}
            <Link
              to={"/offhire/report/update"}
              state={item}
              className={buttonClassName + "secondary"}
              title="Edit Technical Offhire Report"
            >
              <i className="fa fa-edit"></i>
            </Link>
          </>
        );
    

    // Approve/Reject Technical Offhire Report by Fleet Manager
    if (itemStatus === "PENDING" && isFleetManager)
      element = (
        <>
          {element}
          <Link
            to={"/offhire/report/check"}
            state={item}
            className={buttonClassName + "secondary"}
            data-toggle="tooltip"
            data-placement="top"
            title="View Technical Offhire Report"
          >
            <i className="fa fa-arrow-right"></i>
          </Link>
        </>
      );

    // View TECHNICAL OFFHIRE REPORT
    // if (
    //   itemStatus === "APPROVED" ||
    //   (itemStatus === "INCOMPLETE" && !isShipManager)
    // )
    //   element = (
    //     <>
    //       {element}
    //       <Link
    //         to={"/offhire/report/view"}
    //         state={item}
    //         className={buttonClassName+"secondary"}
    //         data-toggle="tooltip"
    //         data-placement="top"
    //         title="View Technical Offhire Report"
    //       >
    //         <i className="fa fa-eye"></i>
    //       </Link>
    //     </>
    //   );

    return element;
  };