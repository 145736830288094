import React, { Component } from "react";
import { appAlert, appAlertError } from "../../core/services/AlertService";
import AnRemoveButton from "../components/buttons/AnRemoveButton";
import AnLink from "../components/buttons/AnLink";
import { EachItem } from "../components/hoc/EachItem";
import { IfItem } from "../components/hoc/IfItem";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import IsAvailable from "../components/hoc/IsAvailable";
import { Button } from "primereact/button";
import { BaseService } from "../../offhire/services/offhire-services";

/**
 * UC - NIU
 * Shows a list of items in a table view.
 * requires:
 * - path: the path to the api endpoint
 * - dataPreprocessor: a function that takes the data from the api endpoint and
 *  converts it to a table view object
 */
class CommonListViewScreen extends Component {
  state = {
    isLoggedIn: true,
    error: null,
    isLoaded: false,
    dataObjectList: [],
    visible: false,
    activeAction: null,
  };

  toastRef;
  handleToastRef = ref=>this.toastRef=ref;

  constructor(props) {
    super(props);
    this.toastRef = null;//React.createRef();
  }

  responceError = (error) => {
    const errorMessage = `Error: ${error.response?.statusText}`;
    // alert(errorMessage);
    if (error.response.status === 401) {
      this.setState({ isLoggedIn: false });
    }
    if (error.response.status === 404) {
      this.toastRef?.show({ severity: 'info', summary: 'Info', detail: 'No data found' });
      this.setState({
        dataObjectList: [],
        isLoaded: true,
        error: "No data found",
      });
    } else {
      this.setState({
        isLoaded: false,
        error: errorMessage,
      });
    }
  };

  componentDidMount() {
    this.refreshData();
  }

  componentDidUpdate(prevProps) {
    // debugger;
    // if (prevProps.params.id !== this.props.params.id) {
    //   this.fetchTrans();
    // }
    if( prevProps.path !== this.props.path){
      this.refreshData();
    }
  }

  /**
   * Get Data from Api
   * Dependent on Entity Service Object 
   * @param {*} path 
   * @returns 
   */
  fetchData = (path) => {
    const { entityService } = this.props;
    if (!entityService) {
      appAlert("Error: Entity Service Error");
      return;
    }

    entityService.get().then(
      (response) => {
        if (response.status === 200) {
          const dataObjectList = response.data;
          this.setState({
            dataObjectList,
            isLoaded: true,
          });
        }
      },
      (error) => {
        this.responceError(error);
      }
    );
  };


  /**
   * Required Api route to fetch data 
   * @param {*} path 
   */
  fetchDataByPath = (path) => {
    BaseService.get(path)
    .then(
      (response) => {
        if (response.status === 200) {
          const dataObjectList = response.data;
          this.setState({
            dataObjectList,
            isLoaded: true,
          });
        }
      },
      (error) => {
        this.responceError(error);
      }
    );
  };



  delete = (selectedRow) => {
    const { entityService } = this.props;
    if (!entityService) {
      appAlert("Error: Entity Service Error");
      return;
    }

    if (!selectedRow[0]) {
      appAlert("Error: No Record Selected");
      return;
    }

    entityService
      .delete(selectedRow[0])
      .then((response) => {
        this.refreshData();
        console.log(response);
      })
      .catch(appAlertError);
  };

  refreshData = ()=>{
    const { path, serviceModeActive } = this.props;
    if( serviceModeActive){
      this.fetchData();
      return;
    }

    if (path === null || path === undefined || path === "") return;

    if (typeof path === "string") {
      this.fetchDataByPath(path);
    } else if (typeof path === "object") {
      this.setState({
        dataObjectList: path,
        isLoaded: true,
      });
    }
  }

  onDialogHide = () => {
    this.setState({ visible: false });
  }


  render() {
    const { dataObjectList, isLoaded, isLoggedIn, visible } = this.state;
    const {
      dataPreprocessor,
      isSimpleTableView,
      canEdit,
      canDelete,
      canView,
      children,
      headerTemplate
    } = this.props;
    const { mainTitle, path, columns } = dataPreprocessor;

    return (
      <IsAvailable isLoaded={isLoaded} isLoggedIn={isLoggedIn} toastRef={this.toastRef} breadcrumbItems={[{ label: mainTitle }]} headerTemplate={headerTemplate}>
        <React.Fragment>
          <div className="row justify-content-center mt-3">
            <div className={isSimpleTableView?"col p-0":"col-sm-12 col-md-12 col-lg-12 col-xl-12 px-3"}>
              <div className="card">
                <div className="card-header bg-white">
                  {isSimpleTableView 
                  ? <h6>{mainTitle}</h6>
                  : <>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}>
                      <h3>{mainTitle}</h3>  
                      {/* New dialog form button -  */}
                      {/* <Button className="p-button p-button-sm p-button-info p-button-rounded" label="Add new" icon="pi pi-plus" onClick={()=>this.setState({visible: true})}>
                      </Button> */}
                    </div>
                    {/* Add Button */}
                    <AnLink className="btn btn-info" to={`${path}/add`} icon="fa fa-plus" style={{
                      position: "fixed",
                      bottom: "45px",
                      right: "45px",
                      borderRadius: "100%",
                      // boxShadow:
                      //   "0 8px 32px 0 rgba(31, 38, 135, 0.37) !important",
                      boxShadow: `8px 8px 12px 0 rgba(0, 0, 0, 0.25),
                        -8px -8px 12px 0 rgba(255, 255, 255, 0.3)`,
                      fontSize: "1.5rem",
                      zIndex: 1000,
                      }}/>
                  </>}
                </div>
                <div className={isSimpleTableView ? "card-body p-0" : "p-0 card-body pb-5" } style={{maxHeight: '75vh'}}>                
                  <IfItem items={dataObjectList} render={(items) =>
                    <DataTable value={items} size="small"  scrollable scrollHeight="72vh"  virtualScrollerOptions={{ itemSize: 30 }} tableStyle={{ width: '100%' }}>
                      <Column key="actions" body={row =>
                        <div className="d-flex justify-content-center">
                          {canEdit && <AnLink type="secondary" to={`${path}/edit`} state={row} icon="fa fa-pencil" title="Edit" />}
                          {canDelete && <AnRemoveButton onConfirm={() => this.delete(row)} />}
                          {canView && <AnLink type="success" to={`${path}/view/${row.id}`} icon="fa fa-eye" />}
                          {/* <AnLink type="warning" to={`${path}/print`} icon="fa fa-print" /> */}
                        </div>
                      } header="Action" style={{ width: '10%'}}/>
                      {/* <Column key="index" field="index" header="#" body={(row, index) => index + 1} /> */}
                      {columns?.map((column, index) => column.body 
                        ? <Column key={index} body={column.body} sortable header={column.header} style={column?.width && { width: column.width, flex: 'unset'}}/>
                        : <Column key={index} field={column.field} sortable header={column.header} style={column?.width && { width: column.width, flex: 'unset'}}/>
                      )}
                      
                    </DataTable>
                  }/>
                </div>
              </div>
            </div>
          </div>
          {children && React.cloneElement(children, { visible, onHide: this.onDialogHide, refreshData: this.refreshData })}
        </React.Fragment>
      </IsAvailable>
    );
  }
}

export default CommonListViewScreen;
